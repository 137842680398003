<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import {
  VxTable,
  VxCellLink,
  VxCellDate,
  VxCellPlayerAvatar,
  VxCellBadges
} from '@/components/table'
import {
  passDataToResource,
  bonuses,
  bonusUsageStatuses,
  playerBonusType,
  bonusUsageReasonTypes
} from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'
import { bonusUrls } from '@/views/bonuses/bonusesService'

export default {
  name: 'BonusUsages',

  components: {
    VxTable,
    VxCellDate,
    VxCellLink,
    VxCellPlayerAvatar
  },
  setup () {
    const resource = passDataToResource(bonuses.usages, {
      requestParams: {
        params: { include: 'player,bonus,reason' }
      }
    })

    const columns = [
      {
        key: 'id',
        label: 'ID',
        sortable: true,
        defaultSort: sortOrders.asc
      },
      {
        key: 'player',
        label: 'Player Id',
        component: VxCellPlayerAvatar
      },
      {
        key: 'bonus',
        label: 'Bonus Id',
        component: VxCellLink,
        tdAttr: ({ id, name, bonus_type }) => ({
          label: name,
          subLabel: `#${id}`,
          href: buildRoute(bonusUrls[bonus_type], { query: { id } })
        })
      },
      {
        key: 'bonus_type',
        label: 'Bonus Type'
      },
      {
        key: 'reason_id',
        label: 'Reason ID',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.promocodes, { query: { id } })
        })
      },
      {
        key: 'reason_type',
        label: 'Reason Type'
      },
      {
        key: 'usage_count',
        label: 'Usage Count'
      },
      {
        key: 'status',
        label: 'Status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [bonusUsageStatuses.disabled]: 'warning',
            [bonusUsageStatuses.active]: 'success',
            [bonusUsageStatuses.expired]: 'danger',
            [bonusUsageStatuses.used]: 'secondary'
          }
        }
      },
      {
        key: 'created_at',
        label: 'Created At',
        component: VxCellDate
      },
      {
        key: 'updated_at',
        label: 'Updated At',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'bonus_type',
        type: filterTypes.select,
        options: Object.values(playerBonusType)
      },
      {
        key: 'reason_id',
        type: filterTypes.number
      },
      {
        key: 'reason_type',
        type: filterTypes.select,
        options: Object.values(bonusUsageReasonTypes)
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(bonusUsageStatuses)
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
